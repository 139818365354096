import * as Actions from "../actions";

const INIT_STATE = {
  feeChallanListLoading: false,
  feeChallanList: [],
  feeChallanDetailLoading: false,
  feeChallanDetail: [],
  feeSavingError : null,
  error: {
    code: null,
    message: null,
  },
};

const feeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.FEE_CHALLAN_LIST_LOADING: {
      return {
        ...state,
        feeChallanListLoading: true,
        feeChallanList: [],
      };
    }
    case Actions.FEE_CHALLAN_LIST_SUCCESS: {
      return {
        ...state,
        feeChallanListLoading: false,
        feeChallanList: [...action.payload],
      };
    }
    case Actions.FEE_CHALLAN_LIST_ERROR: {
      return {
        ...state,
        feeChallanListLoading: false,
        feeChallanList: [],
        error: {
          ...state.error,
          code: action?.payload?.code,
          message: action?.payload?.message,
        },
      };
    }
    case Actions.FEE_CHALLAN_DETAIL_LOADING: {
      return {
        ...state,
        feeChallanDetailLoading: true,
        feeChallanDetail: [],
      };
    }
    case Actions.FEE_CHALLAN_DETAIL_SUCCESS: {
      return {
        ...state,
        feeChallanDetailLoading: false,
        feeChallanDetail: [...action.payload],
      };
    }
    case Actions.FEE_CHALLAN_DETAIL_ERROR: {
      return {
        ...state,
        feeChallanDetailLoading: false,
        feeChallanDetail: [],
        error: {
          ...state.error,
          code: action?.payload?.response.status,
          message: action?.payload?.response.data,
        },
      };
    }
    case Actions.APPLY_REREGISTRATION_SAVING: {
      debugger
      return {
        ...state,
        feeChallanListLoading: true,
        feeChallanList: [],
        feeSavingError: null,
      };
    }
    case Actions.APPLY_REREGISTRATION_SAVING_SUCCESS: {
      return {
        ...state,
        feeChallanListLoading: false,
        feeChallanList: [...action.payload],
        feeSavingError: null,
      };
    }
    case Actions.APPLY_REREGISTRATION_SAVING_ERROR: {
      return {
        ...state,
        feeChallanListLoading: false,
        feeChallanList: [],
        error: {
          
          ...state.error,
          code: action?.payload?.response.status,
          message: action?.payload?.response.data,
        },
        feeSavingError: {
          
          ...state.error,
          code: action?.payload?.response.status,
          message: action?.payload?.response.data,
        },
      };
    }
    
    default: {
      return {
        ...state,
      };
    }
  }
};

export default feeReducer;
