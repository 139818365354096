import * as Actions from "../actions";
import * as common from "../actions/Common";
import feeService from "appServices/feeService";

const fetchFeeChallanList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.FEE_CHALLAN_LIST_LOADING,
    Actions.FEE_CHALLAN_LIST_SUCCESS,
    Actions.FEE_CHALLAN_LIST_ERROR,
    feeService.fetchFeeChallanList,
    query
  );
const fetchFeeChallanDetail = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.FEE_CHALLAN_DETAIL_LOADING,
    Actions.FEE_CHALLAN_DETAIL_SUCCESS,
    Actions.FEE_CHALLAN_DETAIL_ERROR,
    feeService.fetchFeeChallanDetail,
    query
  );

  const applyReregistration = (query) => async (dispatch) =>
    await common.thunkHelper(
      dispatch,
      Actions.APPLY_REREGISTRATION_SAVING,
      Actions.APPLY_REREGISTRATION_SAVING_SUCCESS,
      Actions.APPLY_REREGISTRATION_SAVING_ERROR,
      feeService.applyReregistration,
      query
    );
  


export {
  fetchFeeChallanList,
  fetchFeeChallanDetail,
  applyReregistration
};
