export const FEE_CHALLAN_LIST_LOADING = "FEE_CHALLAN_LIST_LOADING";
export const FEE_CHALLAN_LIST_SUCCESS = "FEE_CHALLAN_LIST_SUCCESS";
export const FEE_CHALLAN_LIST_ERROR = "FEE_CHALLAN_LIST_ERROR";

export const FEE_CHALLAN_DETAIL_LOADING = "FEE_CHALLAN_DETAIL_LOADING";
export const FEE_CHALLAN_DETAIL_SUCCESS = "FEE_CHALLAN_DETAIL_SUCCESS";
export const FEE_CHALLAN_DETAIL_ERROR = "FEE_CHALLAN_DETAIL_ERROR";

export const APPLY_REREGISTRATION_SAVING = "APPLY_REREGISTRATION_SAVING";
export const APPLY_REREGISTRATION_SAVING_SUCCESS = "APPLY_REREGISTRATION_SAVING_SUCCESS";
export const APPLY_REREGISTRATION_SAVING_ERROR = "APPLY_REREGISTRATION_SAVING_ERROR";

